import React from 'react';

const NotFoundPage = () => {
  // We have to check for window object as it is not available in SSR
  const isBrowser = () => typeof window !== 'undefined';
  isBrowser() && window.location.replace('/not-found');

  return <></>;
};

export default NotFoundPage;
